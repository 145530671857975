<template>
  <v-card
    class="district-card"
    :class="{ 'line-view': lineView || city ? true : false }"
  >
    <v-card-title v-if="!lineView && !city">
      <h2>
        {{ rawResult.districtName || rawResult.district }}
      </h2>
      <template v-if="listView">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              icon
              v-on="on"
              @click="
                $emit(
                  'showDistrict',
                  rawResult.districtName || rawResult.district
                )
              "
            >
              <v-icon> mdi-open-in-new </v-icon>
            </v-btn>
          </template>
          <span>Ortsteil öffnen</span>
        </v-tooltip>
      </template>
    </v-card-title>
    <v-card-text>
      <v-row :dense="lineView || city ? false : true">
        <v-col :md="lineView || city ? 4 : 12">
          <template v-if="lineView || city">
            <h2 class="pb-2" :class="{ 'city-header': city }">
              {{ cityHeader || rawResult.districtName || rawResult.district }}
              <template v-if="listView && !city">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      icon
                      v-on="on"
                      @click="
                        $emit(
                          'showDistrict',
                          rawResult.districtName || rawResult.district
                        )
                      "
                    >
                      <v-icon> mdi-open-in-new </v-icon>
                    </v-btn>
                  </template>
                  <span>Ortsteil öffnen</span>
                </v-tooltip>
              </template>
            </h2>
          </template>
          <h3 v-if="!lineView && !city">
            Auftragseingänge
          </h3>
          <v-row no-gutters cols="12">
            <v-col v-if="!lineView && !city" md="4">
            </v-col>
            <v-col md="8">
              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td>FTTH-Aufträge:</td>
                    <td>
                      {{
                        rawResult.ftthContracts ||
                          rawResult.ftthContractsTotal ||
                          0
                      }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
        <v-col :md="lineView || city ? 4 : 12">
          <h3>Adressen</h3>
          <v-row no-gutters cols="12">
            <v-col md="4">
              <v-progress-circular
                :rotate="-90"
                :size="100"
                :width="15"
                :value="
                  rawResult.addressQuota || rawResult.addressQuota === 0
                    ? rawResult.addressQuota * 100
                    : rawResult.addressQuotaTotal * 100
                "
                :color="
                  rawResult.addressQuota || rawResult.addressQuota === 0
                    ? getColor(rawResult.addressQuota)
                    : getColor(rawResult.addressQuotaTotal)
                "
              >
                <template
                  v-if="rawResult.addressQuota || rawResult.addressQuota === 0"
                >
                  {{ formatPercent(rawResult.addressQuota) }}
                </template>
                <template
                  v-else-if="
                    rawResult.addressQuotaTotal ||
                      rawResult.addressQuotaTotal === 0
                  "
                >
                  {{ formatPercent(rawResult.addressQuotaTotal) }}
                </template>
              </v-progress-circular>
            </v-col>
            <v-col md="8">
              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td>Adressen gesamt:</td>
                    <td v-if="Array.isArray(rawResult.addresses)">
                      {{ rawResult.addressesTotal }}
                    </td>
                    <td v-else>
                      {{ rawResult.addresses }}
                    </td>
                  </tr>
                  <tr>
                    <td>Quote:</td>
                    <td
                      v-if="
                        rawResult.addressQuota || rawResult.addressQuota === 0
                      "
                    >
                      {{ formatPercent(rawResult.addressQuota) }}
                    </td>
                    <td
                      v-else-if="
                        rawResult.addressQuotaTotal ||
                          rawResult.addressQuotaTotal === 0
                      "
                    >
                      {{ formatPercent(rawResult.addressQuotaTotal) }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
        <v-col :md="lineView || city ? 4 : 12">
          <h3>Versorgungseinheiten (VE)</h3>
          <v-row dense>
            <v-col md="4">
              <v-progress-circular
                :rotate="-90"
                :size="100"
                :width="15"
                :value="
                  rawResult.supplyUnitQuota || rawResult.supplyUnitQuota === 0
                    ? rawResult.supplyUnitQuota * 100
                    : workaroundSupplyUnitQuotaTotal * 100
                "
                :color="
                  rawResult.supplyUnitQuota || rawResult.supplyUnitQuota === 0
                    ? getColor(rawResult.supplyUnitQuota)
                    : getColor(workaroundSupplyUnitQuotaTotal)
                "
              >
                <template
                  v-if="
                    rawResult.supplyUnitQuota || rawResult.supplyUnitQuota === 0
                  "
                >
                  {{ formatPercent(rawResult.supplyUnitQuota) }}
                </template>
                <template
                  v-else-if="
                    rawResult.supplyUnitQuotaTotal ||
                      rawResult.supplyUnitQuotaTotal === 0
                  "
                >
                  {{ formatPercent(workaroundSupplyUnitQuotaTotal) }}
                </template>
              </v-progress-circular>
            </v-col>
            <v-col md="8">
              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td>VE privat:</td>
                    <td>
                      {{ rawResult.homes }}
                    </td>
                  </tr>
                  <tr>
                    <td>VE gewerblich:</td>
                    <td>
                      {{ rawResult.offices }}
                    </td>
                  </tr>
                  <tr>
                    <td>VE gesamt:</td>
                    <td>
                      {{ rawResult.supplyUnits || rawResult.supplyUnitsTotal }}
                    </td>
                  </tr>
                  <tr>
                    <td>Quote:</td>
                    <td
                      v-if="
                        rawResult.supplyUnitQuota ||
                          rawResult.supplyUnitQuota === 0
                      "
                    >
                      {{ formatPercent(rawResult.supplyUnitQuota) }}
                    </td>
                    <td
                      v-else-if="
                        rawResult.supplyUnitQuotaTotal ||
                          rawResult.supplyUnitQuotaTotal === 0
                      "
                    >
                      {{ formatPercent(workaroundSupplyUnitQuotaTotal) }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DistrictCard',
  components: {},
  props: {
    rawResult: {
      type: Object,
      required: true
    },
    listView: {
      type: Boolean,
      required: false,
      default: false
    },
    lineView: {
      type: Boolean,
      required: false,
      default: false
    },
    city: {
      type: String,
      required: false,
      default: undefined
    }
  },
  data: () => ({
    geoGoetelAddresses: undefined,
    loading: false
  }),
  computed: {
    workaroundSupplyUnitQuotaTotal() {
      let quota = 0;
      if (
        this.rawResult.supplyUnitQuotaTotal === 0 &&
        this.rawResult.ftthContractsTotal > 0
      ) {
        quota =
          this.rawResult.ftthContractsTotal / this.rawResult.supplyUnitsTotal;
      } else {
        quota = this.rawResult.supplyUnitQuotaTotal;
      }
      return quota;
    },
    cityHeader() {
      if (this.city) {
        return this.city + ' (gesamt)';
      } else {
        return undefined;
      }
    }
  },
  watch: {
    data(newVal) {
      this.center = this.formatPoint(newVal[0].geo);
    }
  },

  methods: {
    formatPercent(value) {
      if (value || value === 0) {
        return (value * 100).toFixed(2) + ' %';
      }
    },
    getQuote(item) {
      return ((item.customers / item.addresses) * 100).toFixed(2) + '%';
    },
    getColor(item) {
      const qoute = item * 100;
      let color;
      if (qoute > 60) {
        color = '#20b200';
      } else if (qoute > 45) {
        color = '#96b200';
      } else if (qoute > 30) {
        color = '#d5d100';
      } else if (qoute > 15) {
        color = '#d58600';
      } else {
        color = '#b20000';
      }
      return color;
    }
  }
};
</script>
<style scoped lang="scss">
h2 {
  color: var(--v-primary-base);
  font-size: 26px;
}
h3 {
  border-bottom: 2px solid #ddd;
  margin: 5px 0 10px;
  padding: 5px 0;
}
.city-header {
  font-size: 32px;
}
tr td:first-child {
  width: 68%;
}
.district-card {
  max-width: 600px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 480px;
  float: left;
  // td {
  //   padding-right: 10px;
  //   font-weight: bold;
  // }
}
.line-view {
  max-width: inherit;
  width: 100%;
}
</style>
